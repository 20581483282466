import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import '../../assets/stylesheets/tailwind.scss';
import '../../assets/stylesheets/globals.scss';
import favicon from '!file-loader!../../assets/images/favicon.png';
import logo from '!file-loader!../../assets/images/logo-meta.png';

const Layout = ({ children, title, description, image }) => (
  <div>
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        if (!title) {
          title = data.site.siteMetadata.title;
        } else {
          title = `${title} - ${data.site.siteMetadata.title}`;
        }

        if (!description) {
          description = data.site.siteMetadata.description;
        }

        if (!image) {
          image = `${data.site.siteMetadata.siteUrl}${logo}`;
        }

        return (
          <div>
            <Helmet>
              <html lang="en" />
              <title>{title}</title>

              <meta name="description" content={description} />

              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content={image} />

              <meta
                name="twitter:card"
                content={image ? 'summary' : 'summary_large_image'}
              />
              <meta name="twitter:site" content="@Illianthe" />
              <meta name="twitter:creator" content="@Illianthe" />

              <link rel="shortcut icon" type="image/png" href={favicon} />
              <link rel="shortcut icon" sizes="196x196" href={favicon} />
              <link rel="apple-touch-icon" href={favicon} />
            </Helmet>
            {children}
          </div>
        );
      }}
    />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

export default Layout;
