import React from 'react';
import PropTypes from 'prop-types';
import Root from './root';

const Layout = ({ children }) => <Root>{children}</Root>;

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
