import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layouts/main';
import { home } from '../assets/stylesheets/home.module.scss';
import logo from '../assets/images/logo.svg';

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <div className={home}>
        <header className="py-12 max-w-2xl m-auto px-8">
          <Link to="/" className="logo">
            <img src={logo} alt="Regan Chan" />
          </Link>

          <div className="blurb">
            <p>
              Hello! I&rsquo;m <strong>Regan</strong> and this is my home on the
              web. Here you&rsquo;ll find a random collection of things that
              I&rsquo;ve created over the years as a software developer.
            </p>
            <p>
              You can find me elsewhere on{' '}
              <a href="https://github.com/Illianthe">GitHub</a>,{' '}
              <a href="https://www.flickr.com/photos/illianthe/">Flickr</a>,{' '}
              <a href="https://twitter.com/Illianthe">Twitter</a>, and{' '}
              <a href="https://www.linkedin.com/in/reganchan/">LinkedIn</a>.
            </p>
          </div>
        </header>

        <div className="m-auto py-12 px-8 max-w-2xl blog">
          <h2 id="posts">Blog</h2>

          <table>
            <tbody>
              {posts.map(({ node }) => {
                return (
                  <tr key={node.frontmatter.slug}>
                    <td>{node.frontmatter.created_at}</td>
                    <td>
                      <Link to={`/blog/${node.frontmatter.slug}/`}>
                        {node.frontmatter.title}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="m-auto py-12 px-8 max-w-2xl">
          <h2 id="projects">Projects</h2>

          <div className="project">
            <div>
              <h3>Real-Time Geovisualization</h3>
              <div className="date">December 2018</div>
            </div>

            <div>
              <p>
                A real-time visualization of where videos are being loaded
                around the world.
              </p>
              <div>
                <Link to="/projects/video-plays/">View Details</Link>
              </div>
            </div>
          </div>

          <div className="project">
            <div>
              <h3>Typoo Defender</h3>
              <div className="date">February 2017</div>
            </div>

            <div>
              <p>
                Learn to type slower in this game written for Jamyard, a two-day
                game jam themed after misspellings.
              </p>
              <div>
                <Link to="/projects/typoo-defender/">Play Game</Link>
                <br />
                <a href="https://github.com/Illianthe/jamyard">View Source</a>
              </div>
            </div>
          </div>

          <div className="project">
            <div>
              <h3>IrythiaHS</h3>
              <div className="date">January&ndash;July 2014</div>
            </div>

            <div>
              <p>
                A set of companion tools for <em>Hearthstone</em> including a
                card database, a deck tracker, game analytics, and more.
              </p>
              <div>
                <Link to="/projects/irythia-hs/">View Details</Link>
              </div>
            </div>
          </div>

          <div className="project">
            <div>
              <h3>Monkey Spin</h3>
              <div className="date">January&ndash;March 2011</div>
            </div>

            <div>
              <p>
                An action-packed vertical side-scroller where the objective is
                to keep Bananas &mdash; the lovable, intrepid monkey &mdash;
                surfing on the vines for as long as possible.
              </p>
              <div>
                <Link to="/projects/monkey-spin/">Play Game</Link>
                <br />
                <a href="https://github.com/Illianthe/MonkeySpin">
                  View Source
                </a>
              </div>
            </div>
          </div>

          <div className="project">
            <div>
              <h3>Phoenix Rising</h3>
              <div className="date">December 2010</div>
            </div>

            <div>
              <p>
                Help the phoenix Arkayle in this puzzler written for the second
                iteration of the Game Prototype Challenge, a week-long game jam
                that took place between December 13, 2010 and December 20, 2010.
              </p>
              <div>
                <Link to="/projects/phoenix-rising/">Play Game</Link>
                <br />
                <a href="https://github.com/Illianthe/PhoenixRising">
                  View Source
                </a>
              </div>
            </div>
          </div>

          <div className="project">
            <div>
              <h3>IrythiaCMS</h3>
              <div className="date">2007&ndash;2011</div>
            </div>

            <div>
              <p>
                A simple content management system for (mostly) static sites.
                IrythiaCMS was originally written in the first quarter of 2007
                to serve as a base for a couple high school projects.
              </p>
              <div>
                <a href="https://github.com/Illianthe/IrythiaCMS">
                  View Source
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="m-auto py-12 px-8 max-w-2xl">
          <h2 id="talks">Talks</h2>

          <div className="talk">
            <div>
              <h3>Webpack and Rails</h3>
              <div className="location">KW Ruby</div>
              <div className="date">March 20, 2018</div>
            </div>

            <div>
              <p>
                Webpack has taken the world by storm, becoming the premier asset
                bundler for the web. Vadim and I give an overview of the tool
                and how it integrates with Rails 5.1 now that the latter has
                first-class support. We provide additional tips and tricks
                gleaned from experience in adding Webpack support to
                Vidyard&rsquo;s core services.
              </p>
              <div>
                <a href="https://github.com/Illianthe/mechhead">Demo Project</a>
                <br />
                <a href="/talks/webpack-and-rails.pdf">Slides</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___created_at], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            created_at(formatString: "MMMM DD, YYYY")
            title
            slug
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
